import {Card} from "@mui/material";
import Header from "./Header";
import SelectModalidade from "./SelectModalidade";

export default function CardBase(){
    return (
        <div>
            <Card sx={{ maxWidth: 500, margin: '30px auto', padding: '20px' }}>
                <Header subtitulo='Selecione a modalidade do seu curso'/>
                <SelectModalidade/>
            </Card>
        </div>
    )
}