import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography,} from '@mui/material';
import FormTopics from './FormTopics';


export default function Unidades({ onResult, modalidade }) {
  const [unidades] = useState([1, 2, 3, 4]);
  const [resultados, setResultados] = useState({});
  const [av1, setAV1] = useState();
  const [situacao, setSituacao] = useState(false)

  const handleResult = useCallback(
    (unidade, media) => {
      setResultados((prevResultados) => {
        const newResultados = {
          ...prevResultados,
          [`Unidade ${unidade}`]: media,
        };
        if (modalidade === "eadSemProf") {
          setAV1(calcularAV1semProf(newResultados));
        } else if (modalidade === "semipresencial") {
          const av1 = calcularAV1semi(newResultados, unidade)
          setAV1(av1);
        }
        return newResultados;
      });
      setSituacao(true)
    },
    [modalidade]
  );

  useEffect(() => {
    onResult(av1, situacao);
  }, [av1, onResult, situacao]);

  function calcularAV1semProf(resultados) {
    // pega as medias de todas as unidades
    const mediaUnidades = Object.values(resultados).map(media => parseFloat(media));

    // enquanto não vier as 4 medias das unidades
    while (mediaUnidades.length < 4) {
      //adiciona 0
        mediaUnidades.push(0)      
    }

    // Calcula a soma das média das unidades
    const somaMediaUnidades = Object.values(mediaUnidades).reduce((acc, media) => acc + media, 0);

    const resultado = (somaMediaUnidades /4).toFixed(2);

    return resultado

  }
    
  function calcularAV1semi(resultados, unidade) {
    // pega as medias de todas as unidades
    const mediaUnidades = Object.values(resultados).map(media => parseFloat(media));
    // enquanto não vier as 4 medias das unidades
    while (mediaUnidades.length < 4) {
      //se for a unidade coloca no começo
      if(unidade === 4){
        mediaUnidades.unshift(0);
      }else{
        //se nao coloca no fim
        mediaUnidades.push(0)
      }
      
    }
    
    //soma da media das 3 primeiras unidades
    const somaUnidade1a3 = Object.values(mediaUnidades).slice(0, 3).reduce((acc, media) => acc + media, 0); 

    //resultado da soma com o peso das 3 primeiras unidades
    const resPeso = (somaUnidade1a3*0.275)

    //'soma' media da unidade 4
    const somaUnidade4 = Object.values(mediaUnidades).slice(3, 4).reduce((acc, media) => acc + media, 0);

    //resultado da soma com o peso das ultima unidade
    const resPeso2 = (somaUnidade4*0.175)

    //soma o resultado com os pesos
    const resultado_fim = parseFloat(resPeso) + parseFloat(resPeso2)
   
    return resultado_fim.toFixed(2)
   
  }

  return (
    <div>
      {unidades.map((unidade) => (
        <Accordion key={unidade} sx={{ margin: "10px 0" }}>
          <AccordionSummary sx={{ borderRadius: "8px" }}>
            <Typography variant="h6">{`Unidade ${unidade}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormTopics
              unidade={unidade}
              onResult={handleResult}
              numTopic={
                modalidade === "semipresencial" ? (unidade === 4 ? 4 : 5) : 4
              }
              modalidade={modalidade}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Typography fontSize={"18px"} >
          Média de AV1:<b> {av1} </b>
        </Typography>
      </Box>
    </div>
  );
}