import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';

export default function FormTopics({ unidade, onResult, modalidade, numTopic, situation }) {
  const [notasTopico, setnotasTopico] = useState(Array(numTopic).fill(""));
  const [total, setTotal] = useState(Number(0));
  const [erros, setErros] = useState(Array(numTopic).fill(false));

  const handleChange = (index, value) => {
    const newnotasTopico = [...notasTopico];
    newnotasTopico[index] = value;

    const newErros = [...erros];
    newErros[index] = value < 0 || value > 10;

    setnotasTopico(newnotasTopico);
    setErros(newErros);
    if (!newErros[index]) {
      switch (modalidade) {
        case "semipresencial":
          const resul = calculateResultSemi(newnotasTopico);
          setTotal(resul);
          onResult(unidade, resul);
          break;
        case "eadSemProf":
          const updatedTotal = resultadoEadSemProf(newnotasTopico);
          setTotal(updatedTotal);
          onResult(unidade, updatedTotal);
          break;
        case "presencialHibrido":
          const resulUnidade3 = calculateResultHibrido(newnotasTopico);
          setTotal(resulUnidade3);
          onResult(resulUnidade3);
          break;
        default:
          return
      }

    }
  };

  const resultadoEadSemProf = (notasTopico) => {
    //soma das notas do topico 1 ao 4
    const sum = notasTopico.reduce(
      (acc, curr) => acc + parseFloat(curr || 0),
      0
    );

    const totalTop = sum / 4;
    return totalTop.toFixed(2);
  };

  function calculateResultHibrido(notasAV3) {
    const notasValidas = notasAV3.map((notas) => (notas === '' ? 0 : parseFloat(notas)))
    const sum = notasValidas.reduce((acc, notasValidas) => acc + notasValidas, 0).toFixed(2);
    const media = ((sum) / 4).toFixed(2)

    return media;
  }

  const calculateResultSemi = (notasTopico) => {
    if (numTopic === 5) {
      // Para as três primeiras unidades
      const sum = notasTopico.slice(0, 4).reduce((acc, curr) => acc + parseFloat(curr || 0), 0); // Soma das quatro primeiras notas
      const mediaTopicos1a4 = sum / 4; // Média das quatro primeiras notas
      const notaTopico5 = parseFloat(notasTopico[4] || 0); // Última nota
      const mediaComPesos = mediaTopicos1a4 * 0.64 + notaTopico5 * 0.36; // Média ponderada
      const arrendorarNota = arrendondarValor(mediaComPesos);
      return arrendorarNota;
    } else {
      // Para a última unidade
      const sum = notasTopico.reduce((acc, curr) => acc + parseFloat(curr || 0), 0); // Soma das notas
      const mediaUnidade = sum / numTopic; // Média das notas
      const arrendorarNota = arrendondarValor(mediaUnidade);
      return arrendorarNota;
    }
  };

  function arrendondarValor(value) {
    let roundedValue = parseFloat(value);

    if (!isNaN(roundedValue)) {
      // Arredonda o valor conforme a última casa decimal
      const decimalPart = (roundedValue % 1).toFixed(2);
      const integerPart = Math.floor(roundedValue);

      let finalValue;
      if (decimalPart <= 0.05) {
        finalValue = integerPart;
      } else if (decimalPart > 0.05 && decimalPart < 0.1) {
        finalValue = integerPart + 0.1;
      } else {
        const lastDigit = parseInt(decimalPart.charAt(decimalPart.length - 1));
        if (lastDigit <= 5) {
          finalValue = (Math.floor(roundedValue * 10) / 10).toFixed(2);
        } else {
          finalValue = (Math.ceil(roundedValue * 10) / 10).toFixed(2);
        }
      }
      return finalValue;
    }
  };

  return (
    <div>
      {notasTopico.map((notaTopico, index) => (
        <TextField
          color='warning'
          autoFocus
          key={index}
          label={modalidade === 'presencialHibrido' ? `Nota da AV3 .${index + 1}` : `Nota do Tópico ${index + 1}`}
          type="number"
          value={notaTopico}
          error={erros[index]}
          helperText={erros[index] && "Insira sua nota entre 0.00 e 10.00"}
          onChange={(e) => handleChange(index, e.target.value)}
          fullWidth
          sx={{ margin: "10px 0" }}
        />
      ))}
      <Typography>
        Média da unidade:<b> {total} </b>
      </Typography>
    </div>
  );
}