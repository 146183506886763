import { useState, useEffect } from "react";
import Unidades from "./Unidades";
import {Typography, TextField, Box,} from "@mui/material";
import SituacaoFinal from "./SituacaoFinal";


export default function Fluxo(props){
    const [mediaUnidades, setUnidades] = useState();
    const [notaAv2, setNotaAv2] = useState();
    const [mediaFinal, setNotaFinal] = useState();
    const [erros, setErros] = useState(false);
    const [situacao, setSituacao] = useState(false)

  function handleResult(value, situation) {
    setUnidades(value);
    if(situation === true){
      setSituacao(true)
    }
  }

  useEffect(() => {
    const nFinal = calculaNotaFinal(mediaUnidades);
    setNotaFinal(nFinal)

  }, [mediaUnidades, notaAv2]);

  const handleChange = (value) => {
    let newErros = [erros];
    newErros = value < 0 || value > 10;
    setErros(newErros);
    if(!erros){
      let newNotaAV2 = [notaAv2];
      newNotaAV2 = value;
      setNotaAv2(newNotaAV2);
      setSituacao(true)
    }

  };

  function calculaNotaFinal(mediaUnidades) {
    let nFinal;
    const av1 = mediaUnidades === undefined ? 0 : mediaUnidades
    const av2 = notaAv2 === undefined ? 0 : notaAv2

    nFinal = (av1 * 0.4) + (av2 * 0.6);
    return nFinal.toFixed(2)
  }

  return (
    <div>
      <Typography
        sx={{ textAlign: "center", color: "gray", margin: "45px 0 20px 0" }}
      >
        Orientação: Insira suas notas nos campos abaixo:
      </Typography>
      <Typography
        variant="h6"
        color={"#464444"}
        fontSize={"17px"}
        fontWeight={"600"}
        textTransform={"uppercase"}
      >
        Avaliação 1:
      </Typography>
      <Unidades onResult={handleResult} modalidade={props.modalidade} />

      <Box
        sx={{
          marginTop: "40px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box width={'100%'}>
          <Typography
            variant="h6"
            color={"#464444"}
            fontSize={"17px"}
            fontWeight={"600"}
            textTransform={"uppercase"}
            marginBottom={"10px"}
          >
            Avaliação presencial (AV2):
          </Typography>
          <TextField
            label="Nota da AV2"
            type="number"
            color='warning'
            fullWidth
            error={erros}
            helperText={erros && "Insira sua nota entre 0.00 e 10.00"}
            value={notaAv2}
            onChange={(e) => handleChange(e.target.value)}
            sx={{ margin: "auto", display: "flex" }}
          />
        </Box>
       
      </Box>{ 
        situacao ? <SituacaoFinal
        nota={mediaUnidades === undefined ? 0 : mediaUnidades}
        modalidade="eadSemProf"
        reprovado={mediaFinal >= 6 ? false : true}
        notaFinal={isNaN(mediaFinal) ? 0 : mediaFinal}/> : ''
      }
    </div>
  );

}