import { useState, useEffect } from "react";
import { Typography, TextField, Box, AccordionDetails, AccordionSummary, Accordion } from "@mui/material";
import SituacaoFinal from "../utils/SituacaoFinal";
import FormTopics from "../utils/FormTopics";

export default function FluxoPresencialHibrido() {
    const [mediaTodasUnidades, setMediaTodasUnidades] = useState();
    const [notaAv1, setNotaAv1] = useState();
    const [notaAv2, setNotaAv2] = useState();
    const [mediaFinal, setNotaFinal] = useState();
    const [erros, setErros] = useState(Array(2).fill(false));
    const [situacao, setSituacao] = useState(false)

    function handleResult(value) {
        setMediaTodasUnidades(value);
        const notaFinal = calculaNotaFinal(mediaTodasUnidades)
        setNotaFinal(notaFinal)
    };

    const handleChange = (value) => {
        let newErros = [...erros];
        newErros[0] = value < 0 || value > 10;
        setErros(newErros);
        if(!erros[0]){
            setNotaAv1(value);
            setSituacao(true);
        }
    };

    const handleChange2 = (value) => {
        let newErros = [...erros];
        let newNotaAV2 = [notaAv2];
        newErros[1] = value < 0 || value > 10;
        setErros(newErros);
        if(erros){
            newNotaAV2 = value;
            setNotaAv2(newNotaAV2);
            setSituacao(true);
        }
    };
    
    useEffect(() => {
        const notaFinal = calculaNotaFinal(mediaTodasUnidades)
        setNotaFinal(notaFinal)

    }, [notaAv1, notaAv2, mediaTodasUnidades]);

    function calculaNotaFinal(mediaTodasUnidades) {

        let nFinal;
        const av1Null = notaAv1 === undefined ? 0 : notaAv1
        const av2Null = notaAv2 === undefined ? 0 : notaAv2
        const av3Null = mediaTodasUnidades === undefined ? 0 : mediaTodasUnidades

        nFinal = (av1Null * 0.4)+(av2Null* 0.4) + av3Null*0.2
        return nFinal.toFixed(2)
    }

    return (
        <div>
                <Typography sx={{ textAlign: 'center', color: 'gray', marginTop: '45px' }}>Orientações: Insira suas notas nos campos abaixo:</Typography>

                <Box sx={{ width: ' 100%', margin:'5% 0' }}>
                        <Typography variant="h6" color={'#464444'} fontSize={'17px'} fontWeight={'600'} textTransform={'uppercase'} marginBottom={'5px'}>Avaliação 1:</Typography>
                        <TextField
                            label='Nota da AV1'
                            type="number"
                            color='warning'
                            value={notaAv1}
                            error={erros[0]}
                            helperText={erros[0] && "Insira sua nota entre 0.00 e 10.00"}
                            onChange={(e) => handleChange(e.target.value)}
                            sx={{ margin: 'auto', display: 'flex' }}
                        />
                    </Box>

                <Box sx={{ width: ' 100%', margin:'5% 0' }}>
                        <Typography variant="h6" color={'#464444'} fontSize={'17px'} fontWeight={'600'} textTransform={'uppercase'}  marginBottom={'5px'}>Avaliação 2:</Typography>
                        <TextField
                            label='Nota da AV2'
                            type="number"
                            color='warning'
                            value={notaAv2}
                            error={erros[1]}
                            helperText={erros[1] && "Insira sua nota entre 0.00 e 10.00"}
                            onChange={(e) => handleChange2(e.target.value)}
                            sx={{ margin: 'auto', display: 'flex' }}
                        />
                    </Box>

                    <Box>
                    <Accordion sx={{ border: '1px solid #c4c4c4;'}} >
                        <AccordionSummary>
                            <Typography variant="h6" color={'#464444'} textTransform={'uppercase'} fontSize={'17px'} fontWeight={'600'}>Avaliação 3:</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
                        <FormTopics onResult={handleResult}
                            modalidade={'presencialHibrido'}
                            numTopic={4}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                {
                    situacao ? <SituacaoFinal
                    nota={mediaTodasUnidades === undefined ? 0 : mediaTodasUnidades}
                    modalidade="presencialHibrido"
                    reprovado={mediaFinal >= 6 ? false : true}
                    notaFinal={isNaN(mediaFinal) ? 0 : mediaFinal} /> : ''
                }
        </div>

    )

}