import { Alert, Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";

export default function SituacaoFinal(props) {
    const [notaMin, setNotaMin] = useState();

    useEffect(() => {
    
            switch (props.modalidade) {
                case "presencialHibrido":
                    const min = calculaNotaMin()
                    setNotaMin(min)
                    break
                case "eadSemProf":
                    const notaMinima = (6 - (props.nota * 0.4)) / 0.6
                    setNotaMin(notaMinima.toFixed(2))
                    break
                case "semiEadComProf":
                    notaMin = (6 - (props.nota * 0.4)) / 0.6
                    setNotaMin(notaMin.toFixed(2))
                    break
                default:
                    return
            }    

    }, [props.notaFinal])


    function calculaNotaMin() {
        const notaMinima = (6 - (props.nota * 0.2)) / 0.8
        return notaMinima.toFixed(2)
    }

    return (
        <div>

            <Box marginTop={'20px'}>
                <Alert severity={props.reprovado ? "error" : "success"} 
                sx={{border: 'solid 2px', borderColor: props.reprovado ? '#e15051' : 'green'}}
                variant="standard" >
                    <Typography variant="h7" fontSize={'15px'}
                        sx={{ width: '65%' }}>
                        <b>Nota Final: </b>{props.notaFinal}</Typography>
                    <br />
                    <Typography variant="h7" fontSize={'15px'}
                        sx={{
                            width: '40%'
                        }}><b>Situação: </b>{props.reprovado ? 'Reprovado' : 'Aprovado'}
                    </Typography>
                    <br />
                    {props.reprovado ?
                        <Typography variant="h7" fontSize={'15px'}
                            sx={{ visibility: props.reprovado ? 'visible' : 'hidden' }}><b>Observação: </b>
                            Você precisa obter no mínimo: <b>{notaMin}</b> na recuperação para ser aprovado!</Typography> : ''
                    }
                </Alert>
            </Box>
        </div>

    )

}