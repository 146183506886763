import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import FluxoPresencialHibrido from '../presencialHibrido/FluxoPresencialHibrido';
import FluxoSemiEADcomProf from '../eadConnect/comProfessor/FluxoSemiEADcomProf';
import FluxoSemProf from '../eadConnect/semProfessor/FluxoSemProf';
import Fluxo from './Fluxo';
import { useState, useEffect } from "react";

export default function SelectModalidade() {
    const [modalidade, setModalidade] = useState('');

    const modalidades = [
        'Semipresencial',
        'EAD com web conferência',
        'EAD sem web conferência',
        'Presencial 40%'
      ];

    const handleChange = (event) => {
    setModalidade(event.target.value);
    };

    useEffect(() => { 

    }, [modalidade]);
    
    const handleSimulador = () =>{
      switch (modalidade) {
          case 'Semipresencial':
            return <Fluxo modalidade={'semipresencial'}/>
          case 'EAD sem web conferência':
           return <FluxoSemProf/>
          case 'EAD com web conferência':
            return <FluxoSemiEADcomProf/>
          case 'Presencial 40%':
            return  <FluxoPresencialHibrido/>
          default:
            return
        }

  }
   
 

    return (
            <div >
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" color='warning'>Modalidade</InputLabel>
                <Select
                color='warning'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={modalidade}
                label="Modalidade"
                onChange={handleChange} >
                {modalidades.map((name) => (
                    <MenuItem
                    key={name}
                    value={name}
                    >
                    {name}
                    </MenuItem>
                ))}
                </Select>
                </FormControl>
                <Box display={'block'} flexDirection='column'>
                    {handleSimulador()}
                </Box>

            </div>
                
      );

}