import {Typography, Box} from "@mui/material";

export default function Header(props){
    return(
        <div>
            <Box sx={{  width: '75%',
                        margin: '5% auto'}}>
                        <img style={{width:'100%'}}
                        src="https://sgs.uceff.edu.br/static/media/logo.75997411f99a0294fb50.png"
                        alt="logo Uceff"
                       />
                    </Box>
                <Typography variant="h5" sx={{ textAlign: 'center', color: '#ff9900' }}>
                    <b>Simulador de Notas</b>
                </Typography>
                <Typography sx={{ textAlign: 'center', color: 'gray', marginBottom: '45px' }}>{props.subtitulo}</Typography>
        </div>
    )
}